import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

//css for faq page
import '../../styles/pages/_rules.scss';

class Rules extends Component{

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const langPreferance = this.props.lang;
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper no-bg">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1>{translate('rules-page-heading')}</h1>
                                    </div>
                                    <div className="page-body rules">
                                    {langPreferance === 'fr-CA' ?
                                    <>
                                        <div className="text-block">
                                            <p>AUCUN ACHAT OU PAIEMENT REQUIS POUR PARTICIPER OU GAGNER. UN ACHAT N’AUGMENTERA PAS VOS CHANCES DE GAGNER.<br/><br/>
                                            MICROSOFT N’EST PAS UN COMMANDITAIRE DE LA PROMOTION ET N’EST PAS RESPONSABLE DE SON DÉROULEMENT OU DE SON ADMINISTRATION.</p>
                                    </div>

                                    <div className="text-block">
                                        <h4>DATES CLÉS : </h4>
                                        <p>La promotion <strong>« Recharge tes collations avec Xbox »</strong> 2024 (la <strong>« Promotion »</strong>) commence le 1er août 2024 à 00 : 00 : 00 heure de l’Est (HE) et prend fin le 15 novembre 2024 à 23 : 59 : 59 HE (la <strong>« période de la Promotion »</strong>).  </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>ADMISSIBLITÉ : </h4>
                                        <p>CETTE PROMOTION S’ADRESSE UNIQUEMENT AUX PERSONNES QUI RÉSIDENT LÉGALEMENT ET PHYSIQUEMENT AU CANADA ET ONT ATTEINT L’ GE DE LA MAJORITÉ DANS LEUR PROVINCE OU TERRITOIRE DE RÉSIDENCE AU MOMENT DE LEUR INSCRIPTION. Seules les participations provenant du Canada seront acceptées. Les membres du personnel, représentants et agents (ainsi que les personnes vivant à la même adresse qu’eux, qu’elles aient un lien de parenté ou non) de Mondelez Canada Inc. (<strong>« Commanditaire »</strong>), B STREET Communications Inc. (<strong>« administrateur »</strong>), Microsoft Corporation et chacune de leurs sociétés affiliées, les fournisseurs des prix, les agences de publicité/promotion et toute autre personne ou entité qui participe au développement, à la production, à l’administration ou à l’exécution de la Promotion (collectivement appelés les <strong>« Parties à la Promotion »</strong>) ne peuvent pas participer à la Promotion ni gagner un prix. </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>CONSENTEMENT À ÊTRE JURIDIQUEMENT LIÉ PAR LE PRÉSENT RÈGLEMENT : </h4>
                                        <p>En participant à la Promotion, vous reconnaissez que vous avez lu les modalités du présent règlement (le <strong>« Règlement »</strong>) et que vous acceptez d’être juridiquement lié par ces modalités.</p>
                                    </div>

                                    <div className="text-block">
                                        <h4>COMMENT OBTENIR UN CUP : </h4>
                                        <p>AUCUN ACHAT OU PAIEMENT REQUIS POUR PARTICIPER OU GAGNER. UN ACHAT N’AUGMENTERA PAS VOS CHANCES DE GAGNER. Il y a deux (2) façons d’obtenir un CUP :</p>
                                        <ul>
                                            <li><u><b>Achat admissible</b></u> : Pour obtenir un CUP, achetez n’importe lequel des produits admissibles (chacun étant un <strong>« produit admissible »</strong>) figurant à <a href="#scheduleA">l’Annexe A</a> du Règlement chez un détaillant participant au Canada durant la période de la Promotion. </li>
                                            <li><u><b>Aucun achat requis</b></u> : Pour recevoir un CUP sans acheter un produit admissible, envoyez un courriel à [<a href="mailto:serviceclient@rechargetescollationavecxboxconcours.ca">serviceclient@rechargetescollationavecxboxconcours.ca</a>] durant la période de la Promotion. Le courriel doit comprendre les informations suivantes : (i) ligne Objet : <i>Recharge tes collations avec Xbox – Demande de participation sans achat;</i> (ii) prénom, nom de famille, numéro de téléphone et âge; (iii) une phrase unique et originale d’au moins cinquante (50) mots sur <i>votre véhicule Forza Motor Sport préféré et pourquoi c’est votre préféré</i> (les 2 sont nécessaires). À la réception de l’information demandée conformément au Règlement, l’administrateur vous enverra par courriel un CUP sélectionné au hasard. Les Parties à la Promotion ainsi que leurs dirigeants, administrateurs, agents, représentants, successeurs et ayants droit (collectivement appelés les <strong>« renonciataires »</strong>) ne sont pas responsables de la perte, du vol, du retard, du caractère illisible, des dommages, de l’erreur d’acheminement ou de la destruction des demandes (qui sont toutes nulles) et elles n’acceptent aucune responsabilité que ce soit à ces égards.</li>
                                        </ul>
                                    </div>

                                    <div className="text-block">
                                        <h4>COMMENT PARTICIPER: </h4>
                                        <p>Après avoir obtenu de façon légitime un CUP aux termes de l’article 4 du Règlement, rendez-vous sur le site <a href="https://www.RechargetescollationsavecXbox.ca">www.RechargetescollationsavecXbox.ca</a> (le <strong>« site Web »</strong>) et suivez les instructions affichées à l’écran pour accéder au formulaire officiel de participation à la promotion (le <strong>« Formulaire de participation »</strong>). Inscrivez toute l’information demandée sur le formulaire : (i) vos prénom et nom de famille, numéro de téléphone et une adresse de courriel valide; ii) un CUP valide; (iii) la confirmation que vous avez lu les modalités du Règlement et que vous acceptez d’y être juridiquement lié. Une fois le Formulaire de participation dûment rempli (y compris le CUP dans l’espace prévu), suivez les instructions à l’écran pour l’envoyer (la <strong>« Participation »</strong>). Pour être admissible, votre Participation doit être envoyée et reçue conformément au Règlement. Une fois votre Participation complétée, vous recevrez automatiquement : (i) une notification à l’écran si vous êtes admissible à un prix instantané (voir l’article 8); et (ii) une (1) chance de remporter un grand prix (voir l’article 9).    </p>
                                        <p>Si le Commanditaire constate (à partir de preuves ou d’autres informations mises à sa disposition ou autrement obtenues par lui) qu’une personne a tenté : (i) d’utiliser des noms, des identités ou des adresses de courriel multiples, un système ou un programme automatisé, macro, script, robotisé ou autre et/ou tout autre moyen qui va à l’encontre de l’interprétation que fait le Commanditaire de la lettre et de l’esprit du Règlement pour s’inscrire ou participer à la Promotion ou pour en perturber le déroulement; (ii) d’entrer un CUP qui n’a pas été obtenu de façon légitime conformément au Règlement; et/ou (iii) d’entrer un CUP falsifié, manipulé ou autrement modifié de quelque façon que ce soit (selon ce que le Commanditaire aura déterminé à sa seule et entière discrétion); la personne pourra être disqualifiée de la Promotion, à la seule et entière discrétion du Commanditaire (auquel cas la participation associée au CUP en question sera déclarée nulle et non avenue, et tout droit à l’un ou l’autre des prix associés au CUP sera échu). Une participation peut être rejetée si (à la seule et entière discrétion du Commanditaire) le Formulaire de participation ne contient pas tous les renseignements demandés (y compris un CUP unique obtenu de façon légitime et inscrit à l’endroit prévu) et n’est pas envoyé et reçu conformément au Règlement. Le Commanditaire et les autres Renonciataires ne sont pas responsables du retard, de la perte, du caractère incomplet ou de l’incompatibilité des participations (qui sont toutes nulles) et ils n’acceptent aucune responsabilité que ce soit à ces égards.  </p>
<p>Limite : Une (1) participation par personne, par jour. Aux fins du Règlement, un <strong>« jour »</strong> correspond à la période de 00:00:00 HE à 23:59:59 HE.  </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>VÉRIFICATION: </h4>
                                        <p>L’ensemble des participations, demandes, produits admissibles et CUP peuvent faire l’objet d’une vérification à tout moment et pour toute raison. Le Commanditaire se réserve le droit, à sa seule et entière discrétion, d’exiger une preuve d’identité et/ou d’admissibilité (sous une forme acceptable pour le Commanditaire – y compris, sans s’y limiter, une pièce d’identité officielle) : (i) pour vérifier l’admissibilité d’une personne à participer à la promotion; (ii) pour vérifier l’admissibilité et/ou la légitimité d’une participation, d’une demande, d’un produit admissible, d’un CUP et/ou de toute autre information dans le cadre de la Promotion; et/ou (iii) pour toute autre raison jugée nécessaire par le Commanditaire, à sa seule et entière discrétion, pour l’administration de la Promotion conformément à l’interprétation que fait le Commanditaire de la lettre et de l’esprit du Règlement. De plus, le Commanditaire se réserve le droit, à sa seule et entière discrétion, de demander tout CUP original à des fins de vérification. Le défaut de fournir la preuve demandée par le Commanditaire à l’entière satisfaction de ce dernier (y compris, s’il est demandé, le CUP original) dans le délai précisé par le Commanditaire peut entraîner la disqualification à la seule et entière discrétion du Commanditaire. Le seul facteur déterminant de l’heure aux fins de cette promotion sera le(s) dispositif(s) de chronométrage officiel(s) utilisé(s) par le Commanditaire.</p>
                                    </div>

                                    <div className="text-block">
                                        <h4>LES PRIX : </h4>
                                        <p>Les prix (les <strong>« Prix »</strong>) à attribuer sont les suivants :  </p>
                                        <ul>
                                            <li><u><b>Prix instantanés (25)</b></u> : Il y aura un total de vingt-cinq (25) prix instantanés (individuellement, un <strong>« Prix instantané »</strong> et collectivement, les <strong>« Prix instantanés »</strong>). Chaque Prix instantané consiste en une carte-cadeau Xbox de 100 $CAN. L’utilisation de la carte-cadeau Xbox est assujettie aux conditions de l’émetteur.</li>
                                            <li><u><b>Grands prix (10)</b></u> : Il y aura un total de dix (10) grands prix (individuellement, un <strong>« Grand prix »</strong> et collectivement, les <strong>« Grands prix »</strong>). Chaque Grand prix consiste en une (1) console de jeux personnalisée FORZA Motorsport Series X Édition limitée. Valeur approximative au détail de chaque Grand prix : 2 500 $CAN.</li>
                                        </ul>
<p>Limite d’un (1) Prix instantané par personne/ménage. Limite d’un (1) Grand prix par personne/ménage. Les gagnants d’un Prix instantané demeurent admissibles à un Grand prix.</p>
<p>Chaque Prix doit être accepté tel qu’attribué et n’est ni transférable ni cessible (sauf ce qui sera spécifiquement autorisé par le Commanditaire à sa seule et entière discrétion). Aucune substitution n’est autorisée, sauf au choix du Commanditaire. Le Commanditaire se réserve le droit, à sa seule discrétion, de remplacer un Prix par un prix de valeur égale ou supérieure. En vertu de l’entente d’acceptation du Prix, le gagnant convient d’accepter le Prix <strong>« tel quel »</strong>, et les participants reconnaissent que le Commanditaire n’offre aucune garantie et ne fait aucune représentation, expresse ou implicite, de fait ou en droit, relativement à un Prix, y compris, sans s’y limiter, sa qualité, sa commerciabilité ou sa conformité à un usage particulier, ni de garanties expresses (le cas échéant) offertes exclusivement par le fournisseur d’un prix, garanties qui sont fournies avec un Prix, et n’en est d’aucune façon responsable. Si le gagnant n’accepte pas ou n’utilise pas la totalité du Prix (tel qu’attribué), la partie refusée ou inutilisée du Prix sera perdue et le Commanditaire n’aura aucune autre obligation relativement à ce Prix ou à cette partie du Prix.  </p>
<p>Si, à la suite d’une erreur d’impression, de production, d’informatique, technique, en ligne, sur Internet ou de toute autre erreur, le nombre de Prix réclamés (ou le nombre de Prix d’un type particulier) est supérieur au nombre qui devait être distribué ou attribué conformément au Règlement, le Commanditaire, qui a le droit de mettre fin immédiatement à la Promotion, se réserve également le droit, à sa seule et entière discrétion, d’annuler les réclamations de Prix non valides et/ou de procéder à un tirage au sort parmi tous les participants admissibles pour attribuer le nombre et le type adéquats de Prix, tel que précisé dans le Règlement. Le Commanditaire ou l’un ou l’autre des Renonciataires n’assumeront la responsabilité de plus que le nombre et le type de Prix indiqués dans le Règlement. Le nombre total de Prix à gagner diminuera au fur et à mesure que des Prix seront réclamés et/ou perdus tout au long de la période de la Promotion, conformément au Règlement. </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>PROCESSUS DE SÉLECTION ET DE CONFIRMATION DES GAGNANTS D’UN PRIX INSTANTANÉ : </h4>
                                        <p>Après que vous aurez soumis une participation admissible conformément au Règlement, un message s’affichera automatiquement à l’écran vous informant que vous êtes admissible à un Prix instantané (le ‘Message gagnant). Si vous ne recevez pas ce message, c’est que vous n’êtes pas admissible à un Prix instantané. </p>
                                        <p>Durant la période de la promotion, il y aura vingt-cinq (25) moments gagnants (individuellement appelé <strong>« Moment gagnant »</strong> et collectivement, les <strong>« Moments gagnants »</strong> répartis au hasard tout au long de la période. </p>
    <p>Le premier participant /La première participante admissible qui soumet une participation admissible conformément au Règlement à un Moment gagnant ou immédiatement après un Moment gagnant recevra un Message gagnant et sera admissible au Prix instantané associé à ce Moment gagnant. Si aucune participation admissible n’est soumise à un Moment gagnant ou après un Moment gagnant avant le prochain Moment gagnant, le premier participant/la première participante admissible qui soumet une participation admissible à la suite du deuxième Moment gagnant ne sera admissible qu’au Prix instantané associé au premier Moment gagnant. Le participant/La participante admissible suivant(e) qui soumet une participation admissible par la suite sera alors admissible au Prix instantané associé au deuxième Moment gagnant (et ainsi de suite). </p>
<p>Les chances de gagner un Prix instantané dépendent du nombre de participations admissibles soumises et reçues conformément au Règlement, et du moment où elles sont reçues. </p>
<p>UN PRIX INSTANTANÉ NE SERA ATTRIBUÉ QUE LORSQUE LE COMMANDITAIRE AURA CONFIRMÉ OFFICIELLEMENT QUE LE PARTICIPANT/LA PARTICIPANTE A GAGNÉ LE PRIX INSTANTANÉ CONFORMÉMENT AU RÈGLEMENT </p>
<p>Si vous avez reçu un Message gagnant, vous devrez répondre correctement, sans aide, à une question d’habileté mathématique pour recevoir votre prix.</p>
<p>En participant à la Promotion et en acceptant un Prix instantané, vous (i) confirmez que vous vous conformez au Règlement; (ii) reconnaissez que vous acceptez le Prix instantané (tel qu’attribué); (iii) dégagez le Commanditaire et tous les autres Renonciataires de toute responsabilité relativement à la Promotion, votre participation à celle-ci ainsi qu’à l’attribution et à l’utilisation/mauvaise utilisation du Prix instantané ou de toute partie de ce prix; (iv) consentez à la publication, à la reproduction et à toute autre utilisation de votre nom, votre ville et province/territoire de résidence, votre voix, vos déclarations au sujet de la Promotion et/ou de photographies ou d’autres images sans autre avis ou indemnisation, dans toute publicité présentée par le Commanditaire ou en son nom, sous quelque forme que ce soit, y compris imprimée, radiodiffusée, télédiffusée, ou dans Internet, sauf là où la loi l’interdit.   </p>
<p>Si le gagnant/la gagnante admissible d’un prix instantané a) ne répond pas correctement à la question d’habileté mathématique; b) ne peut pas accepter (ou refuse d’accepter) le prix instantané (tel que fourni) pour quelque raison que ce soit; et/ou c) a enfreint le Règlement (y compris, entre autres, n’a pas répondu correctement à la question d’habileté) (selon ce que déterminera le Commanditaire à sa seule et entière discrétion); il/elle pourra alors être disqualifié(e) à la seule et entière discrétion du Commanditaire (et, le cas échéant, perdra tout droit au Prix instantané). REMARQUE IMPORTANTE : Tout prix perdu ou non réclamé dans le cadre de la Promotion ne sera PAS attribué. </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>PROCESSUS DE SÉLECTION ET DE CONFIRMATION DES GAGNANT(E)S D’UN GRAND PRIX :</h4>
                                        <p>Le 28 novembre 2024 (la <strong>« Date du tirage »</strong>) à Toronto, Ontario vers 12:00:00 HE, dix (10) participant(e)s admissibles seront sélectionnés par tirage au sort parmi toutes les participations admissibles soumises et reçues à cette date conformément au Règlement. Les chances de gagner un Grand prix dépendent du nombre de participations admissibles soumises et reçues conformément au Règlement.   </p>
                                        <p>L’administrateur ou son représentant désigné fera un maximum de deux (2) tentatives pour contacter chaque gagnant(e) admissible (en utilisant l’information fournie dans le Formulaire de participation) dans les cinq (5) jours ouvrables suivant la Date du tirage. Si on ne peut joindre un(e) gagnant(e) admissible tel que susmentionné, ou en cas de retour ou d’avis de non-livraison, la personne pourra être disqualifiée, à la seule et entière discrétion du Commanditaire (et, si elle est disqualifiée, elle renoncera à tout droit à l’égard du Grand prix applicable) et le Commanditaire se réserve le droit, à sa seule et entière discrétion et si le temps le permet, de sélectionner au hasard un(e) autre participant(e) admissible parmi les Participations admissibles restantes (auquel cas les dispositions du présent article s’appliqueront au nouveau gagnant/à la nouvelle gagnante admissible sélectionné(e)).   </p>
<p>Avant d’avoir la confirmation que vous gagnez le Grand prix, vous devrez répondre correctement sans aide, mécanique ou autre, à une question d’habileté mathématique.    </p>
<p>LE GRAND PRIX NE SERA ATTRIBUÉ QUE LORSQUE LE COMMANDITAIRE AURA CONFIRMÉ OFFICIELLEMENT QUE LE PARTICIPANT/LA PARTICIPANTE A GAGNÉ LE GRAND PRIX CONFORMÉMENT AU RÈGLEMENT. AVANT DE RECEVOIR LA CONFIRMATION QU’IL/ELLE A GAGNÉ UN GRAND PRIX, chaque gagnant(e) admissible devra signer et envoyer, dans les cinq (5) jours ouvrables suivant la notification, le formulaire de déclaration et de renonciation du Commanditaire qui, entre autres (i) confirme la conformité au Règlement; (ii) reconnaît l’acceptation du Grand prix (tel qu’attribué); (iii) dégage le Commanditaire et tous les autres Renonciataires de toute responsabilité relativement à la Promotion, sa participation à la Promotion et/ou l’attribution et l’utilisation/la mauvaise utilisation du Grand prix ou d’une partie du Grand prix; (iv) consent à la publication, à la reproduction et à toute autre utilisation de son nom, sa ville et province/territoire de résidence, sa voix, ses déclarations au sujet de la Promotion et/ou de photographies ou d’autres images sans autre avis ou indemnisation, dans toute publicité présentée par le Commanditaire ou en son nom, sous quelque forme que ce soit, y compris imprimée, radiodiffusée, télédiffusée, ou dans Internet, sauf là où la loi l’interdit.  </p>
<p>Si un(e) gagnant(e) admissible : a) ne répond pas correctement à la question d’habileté; b) omet de retourner les documents de la Promotion dûment remplis et dans le délai prescrit; (c) ne peut pas accepter (ou refuse d’accepter) le Grand prix applicable (tel qu’attribué) pour quelque raison que ce soit; et/ou (d) a enfreint le Règlement (y compris, entre autres, n’a pas répondu correctement à la question d’habileté) (selon ce que déterminera le Commanditaire à sa seule et entière discrétion); il/elle sera alors disqualifié((e) à la seule et entière discrétion du Commanditaire (et, le cas échéant, perdra tout droit au Grand prix applicable), et le Commanditaire se réserve le droit, à sa seule et entière discrétion, et si le temps le permet, de sélectionner au hasard un(e) autre participant(e) admissible parmi les participations admissibles restantes pour la Date de tirage, tel qu’énoncé ci-haut (le cas échéant, les dispositions de cet article s’appliquent au nouveau gagnant/à la nouvelle gagnante admissible sélectionné(e).   </p>
                                    </div>

                                    <div className="text-block">
                                        <h4>CONDITIONS GÉNÉRALES :</h4>
                                        <p>La présente Promotion est assujettie aux lois, règles et règlements fédéraux, provinciaux/territoriaux et municipaux applicables. Les décisions du Commanditaire relativement à tous les aspects de la Promotion sont finales et ont force exécutoire pour tous les participants sans droit d’appel. QUICONQUE, DE L’AVIS DU COMMANDITAIRE, NE RESPECTE PAS L’INTERPRÉTATION DE LA LETTRE ET/OU DE L’ESPRIT DU RÈGLEMENT POUR QUELQUE RAISON QUE CE SOIT, PEUT ÊTRE DISQUALIFIÉ À LA SEULE ET ENTIÈRE DISCRÉTION DU COMMANDITAIRE ET EN TOUT TEMPS.   </p>
                                        <p>Le Commanditaire et les autres Renonciataires ne seront pas responsables (i) de toute défaillante d’un site Web ou d’une plateforme avant, pendant ou après la Promotion; (ii) de tout problème technique ou autres problèmes de quelque nature qu’ils soient, y compris, sans s’y limiter, en ce qui a trait aux lignes ou réseaux téléphoniques, aux systèmes en ligne, aux serveurs, aux fournisseurs d’accès, à l’équipement informatique ou aux logiciels; (iii) de l’impossibilité de recevoir, de saisir ou d’enregistrer une demande de participation, un code CUP, un produit admissible et/ou toute autre information, pour quelque raison que ce soit, y compris, sans s’y limiter, des problèmes techniques ou d’engorgement d’Internet ou de tout site Web; (iv) l’absence de CUP sur tout produit admissible; (v) tout dommage causé à l’ordinateur ou à un autre appareil d’un(e) participant(e) ou de toute autre personne en lien avec la participation à la Promotion ou résultant de cette participation; (vi) la désignation incorrecte et/ou erronée de quiconque comme gagnant(e) ou gagnant(e) admissible; et/ou (vii) toute combinaison de ces éléments. </p>
<p>En cas de différend quant à l’identité de la personne qui a soumis une Participation, le Commanditaire se réserve le droit, à sa seule et entière discrétion, de déterminer que la Participation a été soumise par le titulaire autorisé du compte ou de l’adresse de courriel fourni(e) au moment de la participation. Le <strong>« titulaire autorisé du compte »</strong> est la personne à qui un fournisseur Internet ou une autre organisation (p. ex., entreprise, établissement d’enseignement) responsable de l’attribution des adresses de courriel pour le domaine associé à l’adresse fournie, a attribué une adresse de courriel. Un(e) participant(e) pourrait devoir fournir une preuve (sous une forme acceptable pour le Commanditaire, y compris, sans s’y limiter, une pièce d’identité officielle avec photo) démontrant qu’il est le titulaire autorisé du compte de courriel associé à la participation en question.</p>
<p>Le Commanditaire se réserve le droit, à sa seule et entière discrétion, de retirer, de modifier ou de suspendre la Promotion (ou de modifier le Règlement) de quelque façon que ce soit pour toute cause indépendante du contrôle raisonnable du Commanditaire, qui nuit au bon déroulement de la Promotion selon ce que prévoit le Règlement notamment les erreurs, problèmes, virus informatiques, bogues, altérations, interventions non autorisées, fraudes ou toutes autres défaillances. Toute tentative de miner le déroulement légitime de la Promotion de quelque façon que ce soit (tel que déterminé par le Commanditaire à sa seule et entière discrétion) pourra constituer une violation des lois pénales et civiles et, en cas de telle tentative, le Commanditaire se réserve le droit d’exercer des recours et de réclamer des dommages-intérêts dans toute la mesure permise par la loi.   </p>
<p>Le Commanditaire se réserve le droit, à sa seule et entière discrétion, d’annuler, de modifier ou de suspendre la Promotion ou de modifier le Règlement, de quelque façon que ce soit sans préavis ni obligation, en cas d’accident, d’erreur d’impression, d’erreur administrative ou de toute autre nature, ou pour toute autre raison quelle qu’elle soit. Sans limiter la généralité de ce qui précède, le Commanditaire se réserve le droit, à sa seule et entière discrétion, d’administrer un autre test d’habileté, selon ce qu’il juge approprié en fonction des circonstances et/ou pour se conformer à la loi applicable.</p>
<p>Les tarifs de données standards s’appliquent aux participants qui choisissent de participer au moyen d’un appareil mobile. Veuillez contacter votre fournisseur de services pour connaître les prix, les plans de services et les tarifs avant de soumettre une participation à partir d’un appareil mobile. </p>
<p>En participant à la Promotion, chaque participant(e) consent expressément à ce que le Commanditaire, ses agents et/ou représentants stockent, partagent et utilisent les renseignements personnels fournis, uniquement pour les besoins d’administration de la Promotion et conformément à la politique de protection des renseignements personnels du Commanditaire. La présente disposition ne limite aucun autre consentement qu’une personne peut donner au Commanditaire ou à d’autres personnes relativement à la collecte, à l’utilisation et/ou à la divulgation de ses renseignements personnels. Si on vous désigne comme gagnant(e), vos renseignements personnels peuvent également figurer sur une liste de gagnants accessible publiquement. </p>
<p>Le Commanditaire se réserve le droit, à sa seule et entière discrétion, d’adapter les dates, délais et/ou autres aspects de la Promotion stipulés dans le Règlement, dans la mesure jugée nécessaire par le Commanditaire, aux fins de vérification de la conformité d’un participant(e), d’une participation, d’une demande, d’un CUP, d’un produit admissible et/ou de toute autre information au Règlement, ou à la suite de problèmes techniques ou autres, ou à la lumière de toute autre circonstance qui, de l'avis du Commanditaire, à sa seule et entière discrétion, perturbe la bonne administration de la Promotion, tel que prévu par le Règlement, ou pour toute autre raison. </p>
<p>En cas de divergence ou d’incohérence entre les modalités du Règlement en français et les divulgations ou autres déclarations contenues dans tout document relatif à la Promotion y compris, sans s’y limiter, au point de vente, à la télévision, sur support imprimé, en ligne ou dans toute autre publicité, la version anglaise du Règlement et/ou toute instruction ou interprétation du Règlement fournie par tout représentant du Commanditaire, les modalités du Règlement en français prévaut dans la mesure où la loi le permet.</p>
<p>La non-validité ou l’inapplicabilité de l’une ou l’autre des dispositions du Règlement n’a aucune incidence sur la validité ou l’applicabilité de toute autre disposition. S’il est établi que l’une ou l’autre des dispositions est non valide, inapplicable ou contraire à la loi, le Règlement demeure en vigueur et sera interprété conformément aux modalités, comme si la disposition non valide ou illégale n’en faisait pas partie. </p>
<p>Toute la mesure permise par la loi, les problèmes et questions concernant la formulation, la validité, l’interprétation et l’applicabilité du Règlement ou des droits et des obligations des participants, du Commanditaire ou de tout autre Renonciataire relativement à la Promotion seront régis et interprétés conformément aux lois de la province de l’Ontario et aux lois fédérales du Canada qui s’y appliquent, sans égard aux règles ou dispositions en matière de compétence législative ou de conflit de lois qui entraînerait l’application des lois d’une autre compétence. Les parties reconnaissent par la présente la compétence exclusive des tribunaux de l’Ontario relativement à toute mesure à faire appliquer le présent Règlement (ou autrement liée au Règlement) ou relativement à la Promotion.</p></div>




                                    <div className="text-block" id="scheduleA">
                                    <h5>Annexe A</h5>
                                    <p className="split-col">{translate('products.group1', {br: <br />})}</p>
                                    <p className="split-col">{translate('products.group2', {br: <br />})}</p>
                                    <p className="split-col">{translate('products.group3', {br: <br />})}</p>
                                    </div>
                                    
                                    </>
                                    :
                                    <>
                                    <div className="text-block">
                                        <p>NO PURCHASE NECESSARY TO ENTER. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING.<br/>
                                        MICROSOFT IS NOT A SPONSOR OF THIS PROMOTION AND IS NOT RESPONSIBLE FOR ITS EXECUTION OR ADMINISTRATION.</p>
                                    </div>

                                    <div className="text-block">
                                    <h4>KEY DATES:</h4>
                                    <p>The <strong>“Snack On With Xbox”</strong> 2024 Promotion (the <strong>“Promotion”</strong>) begins on August 1, 2024 at 12:00:00 a.m. Eastern Time (<strong>“ET”</strong>) and ends on November 15, 2024 at 11:59:59 p.m. ET (the <strong>“Promotion Period”</strong>).</p>
                                    </div>


                                    <div className="text-block">
                                    <h4>ELIGIBILITY:</h4>
                                    <p>THIS PROMOTION IS OPEN ONLY TO INDIVIDUALS WHO ARE LEGAL RESIDENTS OF AND PHYSICALLY LOCATED IN CANADA AND WHO HAVE REACHED THE LEGAL AGE OF MAJORITY IN THEIR JURISDICTION OF RESIDENCE AT THE TIME OF ENTRY.  Promotion may only be entered from and within Canada. Employees, representatives and agents (and those with who such persons are living – whether related or not) of Mondelez Canada Inc. (<strong>“Sponsor”</strong>), B STREET Communications Inc. (<strong>“Administrator”</strong>), Microsoft Corporation, and each of their respective affiliates and related companies, prize suppliers, advertising/promotion agencies, and any other individual or entity involved in the development, production, administration or fulfillment of the Promotion (collectively, the <strong>“Promotion Parties”</strong>) are not eligible to participate or win a prize in this Promotion.</p>
                                    </div>


                                    <div className="text-block">
                                    <h4>AGREEMENT TO BE LEGALLY BOUND BY THESE OFFICIAL RULES: </h4>
                                    <p>By participating in the Promotion, you are signifying your agreement that you have read and agree to be legally bound by the terms and conditions of these Official Rules and Regulations (the <strong>“Official Rules”</strong>). </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>HOW TO OBTAIN A UPC CODE:</h4>
                                    <p>NO PURCHASE OR PAYMENT IS NECESSARY TO ENTER OR WIN.  A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING.  There are two (2) ways to obtain a UPC Code (each, a <strong>“UPC Code”</strong>), as follows:</p>
                                    <ul>
                                        <li><strong><u>Eligible Purchase</u></strong>: To obtain a UPC Code, purchase any of the eligible products (each, an <strong>“Eligible Product”</strong>) listed in <a href="#scheduleA">Schedule <strong>“A”</strong></a> to these Official Rules from a participating retail location in Canada during the Promotion Period. </li>
                                        <li><strong><u>No Purchase Necessary</u></strong>: To obtain a UPC Code without purchasing an Eligible Product – send an email to [<a href="mailto:customercare@snackonwithxboxcontest.ca">customercare@snackonwithxboxcontest.ca</a> ] during the Promotion Period that includes: (i) the Re: line <i>“Snack On With Xbox – No Purchase Entry Request”</i>; (ii) your first name, last name, telephone number and age; and (iii) a fifty (50) word or more unique and original sentence on <i>Which Forza Motor Sport vehicle  is your favourite and why?</i> (each a <strong>“Request”</strong>). Upon receipt of a valid a Request in accordance with these Official Rules, Administrator will e-mail you a randomly selected UPC Code.  The Promotion Parties, and each of their respective officers, directors, agents, representatives, successors and assigns (collectively, the <strong>“Released Parties”</strong>) are not responsible for, and accept no liability whatsoever in relation to, any lost, stolen, delayed, illegible, damaged, misdirected, late or destroyed Requests (all of which are void).</li>
                                    </ul>
                                    </div>


                                    <div className="text-block">
                                    <h4>HOW TO ENTER: </h4>
                                    <p>Once you have legitimately obtained a valid UPC Code in accordance with Rule 4, visit <a href="https://www.SnackOnWithXbox.ca">www.SnackOnWithXbox.ca</a> (the <strong>“Website”</strong>) and follow the on-screen instructions to go to the Official Promotion Entry Form (the <strong>“Entry Form”</strong>).  Fully complete the Entry Form with all required information, which includes a requirement to: (i) enter your first name, last name, telephone number and valid email address; ii) enter a valid UPC Code; and (iii) signify your agreement that you have read and agree to be legally bound by the terms and conditions of these Official Rules.  Once you have fully completed the Entry Form with all required information (including entering your UPC Code in the space provided), follow the on-screen instructions to submit your completed Entry Form (the <strong>“Entry”</strong>).  To be eligible, your Entry must be submitted and received in accordance with these Official Rules.  Once your Entry is complete, you will automatically: (i) be notified on-screen if you are eligible to win an Instant Prize (See Rule 8); and (ii) earn one (1) Entry for a chance to win a Grand Prize (See Rule 9).</p>
                                    <p>If it is discovered by the Sponsor (using any evidence or other information made available to or otherwise discovered by the Sponsor) that any person has attempted to: (i) use multiple names, multiple identities, multiple email addresses, any automated, macro, script, robotic or other system(s) or program(s) and/or any other means not in keeping with the Sponsor’s interpretation of the letter and spirit of these Official Rules to enter or otherwise participate in or disrupt this Promotion; (ii) falsely enter a UPC Code without legitimately obtaining a UPC Code in accordance with these Official Rules; and/or (iii) enter a UPC Code that has been falsified, manipulated or otherwise altered in any way (all as determined by Sponsor in its sole and absolute discretion); then they may be disqualified from the Promotion in the sole and absolute discretion of the Sponsor (in which case the Entry associated with such UPC Code will be declared null and void and any rights to any Prize(s) associated with such UPC Code will be forfeited in their entirety).  Any Entry may be rejected if (in the sole and absolute discretion of the Sponsor) the Entry Form is not fully completed with all required information (including providing a unique and legitimately obtained UPC Code in the space provided) and submitted and received in accordance with these Official Rules.  The Sponsor and the other Released Parties are not responsible for, and accept no liability whatsoever in relation to, any late, lost, misdirected, delayed, incomplete or incompatible Entries (all of which are void).</p>
                                    <p>Limit: One (1) Entry per person, per Day.  For the purposes of these Official Rules, a <strong>“Day”</strong> is defined as 12:00:00 a.m. ET to 11:59:59 p.m. ET. </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>VERIFICATION: </h4>
                                    <p>All Entries, Requests, Eligible Products and UPC Codes are subject to verification at any time and for any reason.  The Sponsor reserves the right, in its sole and absolute discretion, to require proof of identity and/or eligibility (in a form acceptable to the Sponsor – including, without limitation, government issued photo identification): (i) for the purposes of verifying an individual’s eligibility to participate in this Promotion; (ii) for the purposes of verifying the eligibility and/or legitimacy of an Entry, Request, Eligible Product, UPC Code and/or other information for the purposes of this Promotion; and/or (iii) for any other reason the Sponsor deems necessary, in its sole and absolute discretion, for the purposes of administering this Promotion in accordance with the Sponsor’s interpretation of the letter and spirit of these Official Rules.  Further, the Sponsor reserves the right, in its sole and absolute discretion, to request any original UPC Code for the purposes of verification.  Failure to provide the proof requested by the Sponsor to the complete satisfaction of the Sponsor (including, if required, providing the original UPC Code) within the timeline specified by the Sponsor may result in disqualification in the sole and absolute discretion of the Sponsor.  The sole determinant of the time for the purposes of this Promotion will be the official time-keeping device(s) used by the Sponsor. </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>THE PRIZES:</h4>
                                    <p>The following prizes (the <strong>“Prizes”</strong>) are available to be won:</p>
                                    <ul>
                                        <li><strong><u>Instant Prizes (25)</u></strong>: There will be a total of twenty-five (25) Instant Prizes (each, an <strong>“Instant Prize”</strong> and collectively, the <strong>“Instant Prizes”</strong>) available to be won.  Each Instant Prize consists of a $100 CAD Xbox gift card.  Use of Xbox gift cards is subject to the terms and conditions of the issuer. </li>
                                        <li><strong><u>Grand Prizes (10)</u></strong>: There will be a total of ten (10) Grand Prizes (each, a <strong>“Grand Prize”</strong> and collectively, the <strong>“Grand Prizes”</strong>) available to be won.  Each Grand Prize consists of one (1) Limited Edition Custom FORZA Motorsport Series X Game Console. The total approximate retail value of each Grand Prize is $2500 CAD. </li>
                                    </ul>
                                    <p>There is a limit of one (1) Instant Prize per person/household .  There is a limit of one (1) Grand Prize per person/household.  Instant Prize winners remain eligible to win a Grand Prize.</p>
                                    <p>Each Prize must be accepted as awarded and is not transferable or assignable (except as may be specifically permitted by Sponsor in its sole and absolute discretion).  No substitutions are permitted, except at Sponsor’s option.  Sponsor reserves the right, in its sole discretion, to substitute any Prize with a prize of equal or greater value.  As part of the Prize acceptance agreement, the winner agrees to accept the Prize <strong>“as is”</strong>, and entrants hereby acknowledge that Sponsor has neither made nor are in any manner responsible or liable for any warranty, representation, or guarantee, express or implied, in fact or in law, relative to a Prize, including, without limitation, to a Prize’s quality, merchantability or fitness for a particular purpose or express warranties (if any) provided exclusively by a prize supplier that are sent along with a Prize.  If winner does not accept or use the entire Prize (as awarded), the unaccepted or unused part of the Prize will be forfeited, and Sponsor will have no further obligation with respect to that Prize or portion of the Prize.</p>
                                    <p>If due to printing, production, online, internet, computer, technical or other error of any kind whatsoever, more Prizes (or more Prizes of a particular type) are claimed than intended to be distributed or awarded according to these Official Rules, then, in addition to having the right to terminate the Promotion immediately, the Sponsor reserves the right, in its sole and absolute discretion, to rescind invalid Prize claims and/or conduct a random draw from amongst all eligible claimants to award the correct number and type of Prizes as specified in these Official Rules.  In no event whatsoever will the Sponsor or any of the other Released Parties be liable for more than the number and type of Prizes as stated in these Official Rules.  The total number of Prizes available to be won will decrease as Prizes are claimed and/or forfeited throughout the Promotion Period in accordance with these Official Rules. </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>ELIGIBLE INSTANT PRIZE RECIPIENT SELECTION AND CONFIRMATION PROCESSES:</h4>
                                    <p>Once you have submitted an eligible Entry in accordance with these Official Rules, you will automatically be notified on-screen with a message indicating if you are eligible to win an Instant Prize (the <strong>“Winning Message”</strong>).  If you do not receive a Winning Message, then you are not eligible to win an Instant Prize.  </p>
                                    <p>During the Promotion Period, there are a total of twenty-five (25) winning times (each a <strong>“Winning Time”</strong> and collectively, the <strong>“Winning Times”</strong>) randomly seeded throughout the Promotion Period.</p>
                                    <p>The first eligible entrant who submits an eligible Entry in accordance with these Official Rules on or immediately after a Winning Time will receive a Winning Message and will be eligible to win the Instant Prize associated with that Winning Time. If no eligible Entry is submitted on or after a Winning Time before the next Winning Time, the eligible entrant who first submits an eligible Entry following the second Winning Time will be eligible to win only the Instant Prize associated with the first Winning Time.  The next eligible entrant who submits an eligible Entry following this will then be eligible to win the Instant Prize associated with the second Winning Time (and so forth).</p>
                                    <p>The odds of winning an Instant Prize depend on the number and timing of eligible Entries submitted and received in accordance with these Official Rules.</p>
                                    <p>NO ONE IS THE WINNER OF AN INSTANT PRIZE UNLESS AND UNTIL THE SPONSOR OFFICIALLY CONFIRMS THEM AS THE WINNER OF THE INSTANT PRIZE IN ACCORDANCE WITH THESE OFFICIAL RULES </p>
                                    <p>If you have been notified with a Winning Message, then prior to being confirmed as the winner of the Instant Prize you will be required to correctly answer a mathematical skill-testing question without mechanical or other aid.</p>
                                    <p>By participating in the Promotion and accepting an Instant Prize, you hereby: (i) confirm compliance with these Official Rules; (ii) acknowledge acceptance of the Instant Prize (as awarded); (iii) release the Sponsor and all of the other Released Parties from any and all liability in connection with this Promotion, your participation therein and/or the awarding and use/misuse of the Instant Prize or any portion thereof; and (iv) agree to the publication, reproduction and/or other use of your name, city and province/territory of residence, voice, statements about the Promotion and/or photograph or other likeness without further notice or compensation, in any publicity or advertisement carried out by or on behalf of the Sponsor in any manner whatsoever, including print, broadcast or the internet, except where prohibited by law.</p>
                                    <p>If an eligible Instant Prize recipient: (a) fails to correctly answer the mathematical skill-testing question; (b) cannot accept (or is unwilling to accept) the Instant Prize (as provided) for any reason; and/or (c) is determined to be in violation of these Official Rules (including, but not limited to, failing to correctly answer the skill-testing question) (all as determined by the Sponsor in its sole and absolute discretion); then they may, in the sole and absolute discretion of the Sponsor, be disqualified (and, if disqualified, will forfeit all rights to the Instant Prize).  IMPORTANT NOTE: Any forfeited or unclaimed Instant Prize(s) in this Promotion will NOT be awarded. </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>ELIGIBLE GRAND PRIZE WINNER SELECTION, NOTIFICATION AND CONFIRMATION PROCESSES:</h4>
                                    <p>On November 28, 2024 (the <strong>“Draw Date”</strong>) in Toronto, Ontario at approximately 12:00:00 p.m. ET, ten (10) eligible entrants will be selected by random draw from among all eligible Entries submitted and received to date in accordance with these Official Rules.  The odds of winning a Grand Prize depend on the number of eligible Entries submitted and received in accordance with these Official Rules.</p>
                                    <p>The Administrator or its designated representative will make a maximum of two (2) attempts to contact each eligible winner (using the information provided on the Entry Form) within five (5) business days of the Draw Date.  If an eligible winner cannot be contacted as outlined above, or if there is a return of any notification as undeliverable; then they may, in the sole and absolute discretion of the Sponsor, be disqualified (and, if disqualified, will forfeit all rights to the applicable Grand Prize) and the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to randomly select an alternate eligible entrant from among the remaining eligible Entries (in which case the foregoing provisions of this section shall apply to such newly selected eligible winner).</p>
                                    <p>Prior to being confirmed as the winner of the Grand Prize, you will be required to correctly answer a mathematical skill-testing question without mechanical or other aid.</p>
                                    <p>NO ONE IS THE WINNER OF THE GRAND PRIZE UNLESS AND UNTIL THE SPONSOR OFFICIALLY CONFIRMS THEM AS THE WINNER OF THE GRAND PRIZE IN ACCORDANCE WITH THESE OFFICIAL RULES.  BEFORE BEING DECLARED AS A CONFIRMED GRAND PRIZE WINNER, each eligible winner will be required to sign and return within five (5) business days of notification the Sponsor’s declaration and release form, which (among other things): (i) confirms compliance with these Official Rules; (ii) acknowledges acceptance of the Grand Prize (as awarded); (iii) releases the Sponsor and all of the other Released Parties from any and all liability in connection with this Promotion, their participation therein and/or the awarding and use/misuse of the Grand Prize or any portion thereof; and (iv) agrees to the publication, reproduction and/or other use of their name, city and province/territory of residence, voice, statements about the Promotion and/or photograph or other likeness without further notice or compensation, in any publicity or advertisement carried out by or on behalf of the Sponsor in any manner or medium whatsoever, including print, broadcast or the internet, except where prohibited by law.</p>
                                    <p>If an eligible winner: (a) fails to correctly answer the skill-testing question; (b) fails to return the properly executed Promotion documents within the specified time; (c) cannot accept (or is unwilling to accept) the applicable Grand Prize (as awarded) for any reason; and/or (d) is determined to be in violation of these Official Rules (including, but not limited to, failing to correctly answer the skill-testing question) (all as determined by the Sponsor in its sole and absolute discretion); then they will be disqualified (and will forfeit all rights to the applicable Grand Prize) and the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to randomly select an alternate eligible entrant from among the remaining eligible Entries for the Draw Date as outlined above (in which case the foregoing provisions of this section shall apply to such newly selected eligible winner). </p>
                                    </div>


                                    <div className="text-block">
                                    <h4>GENERAL CONDITIONS:</h4>
                                    <p>This Promotion is subject to all applicable federal, provincial/territorial and municipal laws, rules and regulations.  The decisions of the Sponsor with respect to all aspects of this Promotion are final and binding on all entrants without right of appeal.  ANYONE DEEMED THE SPONSOR TO BE IN VIOLATION OF THE SPONSOR’S INTERPRETATION OF THE LETTER AND/OR SPIRIT OF THESE OFFICIAL RULES FOR ANY REASON IS SUBJECT TO DISQUALIFICATION IN THE SOLE AND ABSOLUTE DISCRETION OF THE SPONSOR AT ANY TIME.</p>
                                    <p>The Sponsor and the other Released Parties will not be liable for: (i) any failure of any Website or any platform before, during or after the Promotion; (ii) any technical malfunction or other problems of any nature whatsoever, including, without limitation, those relating to the telephone network or lines, computer on-line systems, servers, access providers, computer equipment or software; (iii) the failure of any Entry, Request, UPC Code, Eligible Product and/or other information to be received, captured or recorded for any reason whatsoever, including, but not limited to, technical problems or traffic congestion on the internet or at any website; (iv) the failure of any Eligible Product to include a UPC Code; (v) any injury or damage to an entrant’s or any other person’s computer or other device related to or resulting from participating in the Promotion; (vi) anyone being incorrectly and/or mistakenly identified as a winner or eligible winner; and/or (vii) any combination of the above.</p>
                                    <p>In the event of a dispute regarding who submitted an Entry, the Sponsor reserves the right, in its sole and absolute discretion, to deem the Entry to have been submitted by the authorized account holder of the email address submitted at the time of entry.  <strong>“Authorized account holder”</strong> is defined as the person who is assigned an email address by an internet provider, online service provider, or other organization (e.g. business, educational institute, etc.) that is responsible for assigning email addresses for the domain associated with the submitted email address.  An entrant may be required to provide proof (in a form acceptable to the Sponsor – including, without limitation, government issued photo identification) that they are the authorized account holder of the email address associated with the Entry in question.</p>
                                    <p>The Sponsor reserves the right, in its sole and absolute discretion, to withdraw, amend or suspend this Promotion (or to amend these Official Rules) in any way, in the event of any cause beyond the reasonable control of the Sponsor that interferes with the proper conduct of this Promotion as contemplated by these Official Rules, including, without limitation, any error, problem, computer virus, bugs, tampering, unauthorized intervention, fraud or failure of any kind whatsoever.  Any attempt to undermine the legitimate operation of this Promotion in any way (as determined by Sponsor in its sole and absolute discretion) may be a violation of criminal and civil laws and should such an attempt be made, the Sponsor reserves the right to seek remedies and damages to the fullest extent permitted by law.</p>
                                    <p>The Sponsor reserves the right, subject in its sole and absolute discretion, to cancel, amend or suspend this Promotion, or to amend these Official Rules, in any way without prior notice or obligation, in the event of any accident, printing, administrative, or other error of any kind, or for any other reason whatsoever.  Without limiting the generality of the forgoing, the Sponsor reserves the right, in its sole and absolute discretion, to administer an alternate test of skill as it deems appropriate based on the circumstances and/or to comply with applicable law.</p>
                                    <p>Standard data rates apply to participants who choose to participate in the Promotion via a mobile device.  Please contact your service provider for pricing and service plan information and rates before mobile device participation.</p>
                                    <p>By entering this Promotion, each entrant expressly consents to the Sponsor, its agents and/or representatives, storing, sharing and using the personal information submitted only for the purpose of administering the Promotion and in accordance with Sponsor’s <a href="https://www.mondelezinternational.com/Canada/Privacy-Policy" target="_blank" rel="noopener noreferrer">privacy policy</a>.  This section does not limit any other consent(s) that an individual may provide the Sponsor or others in relation to the collection, use and/or disclosure of their personal information. If you are selected as a winner, your information may also be included in a publicly-available winner’s list.</p>
                                    <p>The Sponsor reserves the right, in its sole and absolute discretion, to adjust any of the dates, timeframes and/or other Promotion mechanics stipulated in these Official Rules, to the extent deemed necessary by the Sponsor, for purposes of verifying compliance by any entrant, Entry, Request, UPC Code, Eligible Product and/or other information with these Official Rules, or as a result of any technical or other problems, or in light of any other circumstances which, in the opinion of the Sponsor, in its sole and absolute discretion, affect the proper administration of the Promotion as contemplated in these Official Rules, or for any other reason.</p>
                                    <p>In the event of any discrepancy or inconsistency between the terms and conditions of these English Official Rules and disclosures or other statements contained in any Promotion-related materials, including, but not limited to, point of sale, television, print, online or other advertising, the French version of these Official Rules and/or any instructions or interpretations of these Official Rules given by any representative of the Sponsor, the terms and conditions of these English Official Rules shall prevail, govern and control to the fullest extent permitted by law.</p>
                                    <p>The invalidity or unenforceability of any provision of these Official Rules shall not affect the validity or enforceability of any other provision.  In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Official Rules shall otherwise remain in effect and shall be construed in accordance with the terms as if the invalid or illegal provision were not contained herein.</p>
                                    <p>To the fullest extent permitted by applicable law, all issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules or the rights and obligations of participants, Sponsor or any of the other the Released Parties in connection with the Promotion will be governed by and construed in accordance with the domestic laws of the Province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of any other jurisdiction’s laws.  The parties hereby consent to the exclusive jurisdiction and venue of the courts located in Ontario in any action to enforce (or otherwise relating to) these Official Rules or relating to this Promotion. </p>
                                    </div>

                                    <div className="text-block" id="scheduleA">
                                    <h5>Schedule <strong>“A”</strong></h5>
                                    <p className="split-col">{translate('products.group1', {br: <br />})}</p>
                                    <p className="split-col">{translate('products.group2', {br: <br />})}</p>
                                    <p className="split-col">{translate('products.group3', {br: <br />})}</p>
                                    </div>

                                    </>
                                    }
                                
                                    </div>
                                    { contestState===0 && 
                                        <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Rules;