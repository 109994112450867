import React from 'react';
import { FormattedMessage } from 'react-intl';

const translate = (id, value={}) => <FormattedMessage id={id} values={{...value}} />;
const translatedlink = (id) => <FormattedMessage id={id}>{
        (link)=>{
            const pre = link[0].includes('@') ? 'mailto:' : '';
            const targ = link[0].includes('http') ? '_blank' : '';
            return <a href={pre+link} target={targ}>{link}</a>
        }
    }</FormattedMessage>;

export { translate, translatedlink };