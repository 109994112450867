import React, { Component, Fragment } from 'react';
import InjectIntl from 'react-intl-inject';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import {InlineShareButtons} from 'sharethis-reactjs';


// import packshot from '../../../src/images/packages.png';
// import packshotfr from '../../../src/images/packages.png';

//images
import twitterIcon from '../../images/twitter.png';
import facebookIcon from '../../images/facebook.png';
import downloadBtn from '../../images/download.png';



//social share and :og tags
// import LosingKeySocialShare from '../LosingKeySocialShare';

//css
import '../../styles/pages/_you-lose.scss';
import { translate } from '../../i18n/translate';

class YouLose extends Component{
    
    state = {
        buttonHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //component did mount
    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        // language cookie setting for IG image
        // const langSelected = Cookie.get('lang-selected');
        // if(!langSelected) {
        //     Cookie.set('lang-preferance', 'en-US')
        // }

        // const enterNowButton = document.querySelector('.btn-secondary')
        // enterNowButton.addEventListener('click', (e) =>{
        //     e.preventDefault();
        //     window.location.reload()
        // })

        // const menuEnterContest = document.querySelector('#menu-enterContest')
        // menuEnterContest.addEventListener('click', (e) =>{
        //     e.preventDefault();
        //     window.location.reload()
        // })

        const igDownloadBtn = document.querySelector('#ig-download');
        igDownloadBtn.addEventListener('click', (e)=>{
            // ReactGA.event({
            //     category: 'Download',
            //     action: 'Instagram'
            //   });
        })
    }

    //facebook share new window
    fbShare = () => {
        // ReactGA.event({
        //     category: 'ShareThis',
        //     action: 'facebook'
        //   });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? 'rechargetescollationsavecxbox.ca' : 'snackonwithxbox.ca'; 
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    //twitter share new window // not used.
    twShare = () => {
        // ReactGA.event({
        //     category: 'ShareThis',
        //     action: 'twitter'
        //   });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? 'rechargetescollationsavecxbox.ca' : 'snackonwithxbox.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}&text=Vous%20pouvez%20GAGNER%2A%20une%20exp%C3%A9rience%20de%20%C2%AB%20gaming%20%C2%BB%20Xbox%20en%20suite.%20Il%20y%20a%2010%20consoles%20Xbox%20Series%20S%20sur%20mesure%20%C3%A0%20gagner.` : `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}&text=You%20Could%20WIN%2A%20an%20Xbox%20Gaming%20Suite%20Experience.%20There%20are%2010%20Custom%20Xbox%20Series%20S%20consoles%20to%20be%20won.`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    render(){

        // const remainingKeys = 5; 
        // const currentPage = window.location.href;
        // const siteDomain = 'snackonwithxbox.ca'; 
        // const langPreferance = this.props.lang;

        return(
            <Fragment>
                <div className="main-wrapper alt-bg you-lose">
                    {/* <LosingKeySocialShare remainingKeys={remainingKeys} /> */}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="you-win">
                                        <h1>{translate('tryAgain')}</h1>
                                        {/* <p>{translate('tryAgain.body', {br: <br />})}</p> */}
                                        <p>{translate('tryAgain.body', {br: <br />})}
                                            {/* <NavLink to="/products">{translate('popupBodylinkAlt')}</NavLink>
                                            {translate('tryAgain.bodyb')} */}
                                        </p>
                                        <NavLink exact to="/products" className="btn-secondary">
                                            {translate('menu.item.5')}
                                        </NavLink>
                                        
                                        <h2>{translate('tryAgain.heading2')}</h2>
                                        {/* <p>{translate('tryAgain.body2')}</p> */}
                                        <div className="social-container">
                                            <button onClick={this.fbShare} style={{background: 'transparent', border: 'none'}}><img src={facebookIcon} alt="Caramilk faceook"/></button>
                                            <button onClick={this.twShare} style={{background: 'transparent', border: 'none'}}><img src={twitterIcon} alt="caramilk twitter"/></button>
                                        </div>
                                        <p>{translate('tryAgain.body3')}</p>
                                        <a href={ `/xbox_instagram.jpg` } target="_blank" rel="noopener noreferrer" download id="ig-download">
                                            <InjectIntl>
                                                {({ intl }) => (
                                                    <img className="download-btn" src={downloadBtn} alt={intl.formatMessage({ id: 'download' })} />
                                                )}
                                            </InjectIntl>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouLose;