import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
// import Cookie from 'js-cookie';
import Recaptcha from 'react-google-invisible-recaptcha';
import PinForm from '../forms/PinForm';
import InfoForm from '../forms/InfoForm';
import YouWon from './YouWon';
import YouLose from './YouLose';
// // import ReactGA from 'react-ga'; //google analytics

//import css
import '../../styles/pages/_enter-contest.scss';

const RECAPTCHA_SITE_KEY = '6LcMMh4iAAAAAJOpqwAtAkYGRwI9zL_PSfcFv-7d';

class EnterContest extends Component{

    state = {
        step: 1,
        pin : "",
        fName : "",
        lName : "",
        email: "",
        phone: "",
        age: false,
        rules: false,
        optin: false,
        pinFormActive: true,
        loading: false,
        reCapToken: "",
        processing: false,

        fNameError: "",
        lNameError: "",
        emailError: "",
        ageError: "",
        phoneError: "",
        rulesError: "",
        optinError: "",

        timestamp: ""
    }

    //go to next step
    nextStep = (e) => {
        e.preventDefault();
        const { step, pin } = this.state;
        
        if (pin !== ""){
            this.setState({
                step: step + 1
            })
        }
    }

    //go back
    prevState = () => {
        //const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    //input fields on change or on type
    handleChange = input => e => {
        this.setState({
            [input] : e.target.value
        })

        //setting error to be blank once start typing
        const errorState = `${input}Error`;
        this.setState({
            [errorState] : ""
        })
    }

    //check box on click
    handleCheckbox = e => {
        this.setState({
            [e.target.name] : e.target.checked
        })

        ////setting error to be blank once clicked
        const errorState = e.target.name + 'Error';
        this.setState({
            [errorState] : ""
        })
    }

    //close warning from PinForm 
    inputPinClickHandler = () => {
        this.setState({
            step: 1
        })
    }

    // PIN input field onkeypress 
    inputKeypressHandler = () =>{
        if (this.state.step !== 1){
            this.setState({
                step: 1
            })
            console.log('step update');
        }
    }

    //error popup modal close on info form
    closeWarningfromInfoForm = () =>{
        this.setState({
            step: 2
        })
    }


    //validate form
    validateForm = () => {
        console.log('validation is called')
        const { fName, lName, email, age, rules, optin, phone } = this.state;
        let fNameError = "";
        let lNameError = "";
        let emailError = "";
        let ageError = "";
        let rulesError = "";
        let optinError = "";
        let phoneError = "";

        //getting language cookei
        const langPreferance = this.props.lang;

        if (langPreferance === 'fr-CA'){
            fName ? fNameError = "" : fNameError = "Entrez votre prénom s'il vous plait";
            lName ? lNameError = "" : lNameError = "Veuillez entrer votre nom de famille";
            age ? ageError = "" : ageError = "Veuillez confirmer que vous avez l'âge de la majorité dans la province ou le territoire où vous résidez";
            rules ? rulesError = "" : rulesError = "Veuillez confirmer que vous avez lu et respecté les règles et règlements du concours et la politique de confidentialité de Mondelēz Canada";
            optin ? optinError = "" : optinError = "Veuillez confirmer que vous consentez";
            if(email && email.includes('@') && email.includes('.') ) {emailError = "";} else {  emailError = "Veuillez fournir une adresse email valide"; }

        } else{
            fName ? fNameError = "" : fNameError = "Please enter your first name";
            lName ? lNameError = "" : lNameError = "Please enter your last name";
            phone ? phoneError = "" : phoneError = "Please enter your phone number";
            age ? ageError = "" : ageError = "Please confirm that you are the age of majority in the province/territory where you reside";
            rules ? rulesError = "" : rulesError = "Please confirm that you have read and comply with the Contest rules and regulations and the Mondelēz Canada Privacy Policy";
            optin ? optinError = "" : optinError = "Please confirm that you consent";
            if(email && email.includes('@') && email.includes('.') ) {emailError = ""; } else {  emailError = "Please provide a valid email address"; }
        }

        if (fNameError || lNameError || emailError || phoneError || ageError || rulesError || optinError){
        this.setState({
            fNameError,
                lNameError,
                emailError,
                phoneError,
                ageError,
                rulesError,
                optinError
            })
            return false;
        }

        return true;
    }

    //handle recaptcha
    handleReCaptcha = () => {
        const { processing } = this.state;
      
        if (processing) {
            console.log('processing'); return;
        }
        const validateForm= this.validateForm();
        const recaptcha = this.recaptcha;
        
        console.log('before validation', validateForm)
        if (validateForm === true){
            this.setState({processing:true});
            recaptcha.execute().then(token => {
                console.log(token)
                this.setState({ reCapToken: token })
                this.handleSubmit();
            });
            console.log('validation passed')
        } else{
            console.log('validation did not pass')
        }
    }

    verifyCallback = res =>{
        console.log(res)
    }

    //handle form submit
    handleSubmit = e => {
        //e.preventDefault();
        console.log('after submission')

        const { pin, fName, lName , phone, email, age, rules, optin, reCapToken } = this.state;

            //loading state on
            this.setState({loading: true})

            // axios.post('https://xbox-mondelez-contest.herokuapp.com/entries', {
           axios.post(process.env.REACT_APP_BACKEND_URL+'/entries', {
                "email": email,
                "firstname": fName,
                "lastname": lName,
                "phone": phone,
                "age": age,
                "rules": rules,
                "optin": optin,
                "sku":pin,
                "token" : reCapToken,
                "lang" : this.props.lang
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({loading: false,processing:false})
                console.log('res', res)
                if ( res.status === 200 ){
                    this.setState({ step: 3, timestamp: res.data.timestamp }, () => { this.props.history.push(`?status=winner`); window.scrollTo(0, 0); })
                } else if ( res.status === 202 ){
                    this.setState({ step: 4 }, () => { window.scrollTo(0, 0); })
                } 
            }).catch(err => {
                const { step } = this.state;
                this.setState({loading: false,processing:false})
                //console.log('currently on step',step);
                if (step===3||step===4) return;
                console.log('Error', err.response)
                if ( err.response.status === 403 ){
                    this.setState({ step: 5})
                } else if ( err.response.status === 405 ) {
                    this.setState({ step: 6 })
                } else if( err.response.status === 406 ){
                    this.setState({ step: 7 })
                } else if ( err.response.status === 411 ) {
                    this.setState({ step: 8})
                } else if( err.response.status === 412 ){
                    this.setState({emailError : "Please enter a valid email."})
                } else if( err.response.status === 413 ){
                    this.setState({phoneError : "Please enter a valid phone number."})
                } else if ( err.response.status === 429 ) {
                    this.setState({ step: 9})
                } else if ( err.response.status === 451 ) {
                    this.setState({ step: 10})
                } else if ( err.response.status === 500 ) {
                    this.setState({ step: 11})
                } else if ( err.response.status === 503 ) {
                    this.setState({ step: 12})
                } else if ( err.response.status === 400 ) {
                    this.setState({ step: 13})
                } else if ( err.response.status === 401 ) {
                    this.setState({ step: 14})
                } else if ( err.response.status === 509 ) {
                    this.setState({ step: 8})
                }
            })
    }

    componentDidMount(){
        //initiate google analytics
        // // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){

        const { step, loading } = this.state;
        const { pin, fName, lName , phone, email, age, rules, optin, timestamp } = this.state;
        const values = { pin, fName, lName, phone, email, age, rules, optin };
        const { fNameError, lNameError, emailError, phoneError, ageError, rulesError, optinError } = this.state;
        const errorMessages = { fNameError, lNameError, emailError, phoneError, ageError, rulesError, optinError };
        const langPreferance = this.props.lang;

        switch(step){
            default: 
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.nextStep} handleChange={this.handleChange}  lang={langPreferance} />
                    </Fragment>
                );

            case 2:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 3:
                return(
                    <YouWon timestamp={timestamp} />
                )

            case 4:
                return(
                    <YouLose lang={langPreferance} />
                )

            case 5: 
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.nextStep} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? `Code non valide. Le code inscrit ne doit pas déjà avoir été utilisé.` : `The code is not valid: Code must be unused`} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                    </Fragment>
                )

            case 6: 
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.nextStep} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code non trouvé dans notre base de données.' : 'The code you have entered was not found'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                    </Fragment>
                )

            case 7:
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.nextStep} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code erroné.' : 'The code you have entered is incorrect'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                    </Fragment>
                )

            case 8:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} invalidEntry={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 9:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} tooManyReqIp={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 10:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} tooManyReqEmail={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 11:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} internalError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 12:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} dbError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 13:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} badReq={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )
            
            case 14:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleReCaptcha={this.handleReCaptcha} validateForm={this.validateForm} handleSubmit={this.handleSubmit} captchaError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY}  verifyCallback={this.verifyCallback} />
                    </Fragment>
                )
        }
    }
}

export default withRouter(EnterContest);