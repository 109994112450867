import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Recharger tes collations avec Xbox!',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        // 'menu.item.winner' : 'ANCIENS GAGNANTS',
        'menu.item.1': 'PARTICIPEZ AU CONCOURS',
        'menu.item.2': 'DÉTAIL DES PRIX',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RÈGLEMENT',
        'menu.item.5': 'PRODUITS PARTICIPANTS',
        
        'contact-email': 'serviceclient@rechargetescollationavecxboxconcours.ca',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Politique de protection des renseignements personnels (Anglais)',
        'footer.link3' : 'Politique de protection des renseignements personnels (Québec)',
        'footer.link4' : 'Conditions générales d’utilsation',
        'footer.link5' : 'Politiques sur l’accessibilitié',
        'footer.sponsor': 'Commanditaire : Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 ©Groupe Mondelez International. Les marques de commerce appartiennent à leur propriétaire respectif.{br}{br}Xbox est une marque déposée de Microsoft. Microsoft n’est pas un Commanditaire.',

        //homapge
        'homepage.gameon' : 'C’EST PARTI !',
        'homepage.unlock': 'Vous pourriez',
        'homepage.prize' : 'GAGNER<sup>*</sup> 1 <small>de</small> 3 expériences de « gaming » Xbox en suite',
        'homepage.prizeSub' : '<small>(VDA 7,500 $)</small>',
        'homepage.prizeSub2' : '<small>(VDA 750 $)</small>',
        'homepage.pluscash': 'Plus 1 de 10 consoles Xbox Series S sur mesure!',
        'homepage.cta' : "Faites défiler l’écran pour apprendre comment participer",
        'homepage.howtoenter' : 'COMMENT ÇA MARCHE',
        'homepage.step1head' : 'ÉTAPE 1',
        'homepage.step1' : 'Achetez un ',
        'homepage.step1post' : ' Mondelez.',
        'homepage.step2head' : 'ÉTAPE 2',
        'homepage.step2' : 'Recherchez les numéros CUP** à 11 chiffres figurant sur l’emballage de votre ',
        'homepage.step2post' : ' Mondelez.',
        "homepage.omit": "***Omettez le premier chiffre à gauche du code.",
        'homepage.step3head' : 'ÉTAPE 3',
        'homepage.step3' : 'Saisissez les CUP pour avoir une chance de gagner* une expériences de « gaming » Xbox en suite en plus d’une de dix consoles Xbox Series S sur mesure.',

        'contestClosedHead' : 'CONCOURS TERMINÉ',
        'contestClosedText' : 'Merci pour votre participation!',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterNow' : 'PARTICIPER',

        'legal' : "*AUCUN ACHAT REQUIS. Ouvert aux résidents du Canada ayant atteint l’âge de la majorité.   Le concours commence le 1 er août 2024 à 00:00:00 HE et se termine le 15 novembre 2024 à 23:59:59 HE. Pour tous les détails sur la participation, les produits participants et le règlement complet, rendez-vous sur www.RechargetescollationsavecXbox.ca. À gagner : (i) 25 prix instantanés (VAD : 100 $CAN chacun) consistant en des cartes-cadeaux Xbox; (ii) 10 grands prix consistant en une console de jeux Xbox Series X personnalisée (VAD : 2 500 $CAN chacune).  Les chances de gagner dépendent du nombre de participations admissibles. Réponse à une question d’habileté requise. Les marques de commerce appartiennent à leur propriétaire respectif. Xbox est une marque déposée de Microsoft. Microsoft n’est pas un Commanditaire.",
        'legal.link' : 'www.RechargeTesCollationsAvecXbox.ca',

        //coming soon and home page
        'comingsoon.head1' : "Préparez-vous à",
        'comingsoon.head2' : "lancer les moteurs et à",
        'comingsoon.head3' : "recharger vos collations avec Xbox!",
        'comingsoon' : 'Ça s’en vient – 1<sup>er</sup>\u00A0août\u00A02024',

        //enter contest page
        'step1': 'Étape 1',
        'enterPin': 'ENTREZ 1 CUP',
        'enterKeyDescription': "Entrez les numéros CUP** à 11 chiffres figurant sur l’emballage de votre ",
        'enterKeyLink': "produit participant",
        'enterKeySmall': '***Omettez le premier chiffre à gauche du code.',
        'popupBodytext' : 'Recherchez le code UPC au dos de votre achat d’un ',
        'popupBodylink' : 'produit participant',
        'pin': 'Code CUP',
        'nextStep': 'PROCHAINE ÉTAPE',

        'step2': 'Étape 2',
        'enterInfo': 'INFORMATION DE PARTICIPATION',
        'enterInfoDescription': 'Fournissez vos informations de participation au concours pour participer et voir si vous avez gagné un prix instantané!',
        'firstName': 'Prénom',
        'lastName': 'Nom',
        'emailAddress': 'Adresse courriel',
        'phone': 'Numéro de téléphone',
        'required': '*Champs requis',
        'infoForm.age': "Je confirme que j'ai atteint l'âge de la majorité dans la province ou le territoire où je réside*",
        'infoForm.rules' : "J'ai lu et je confirme ma conformité au ",
            'infoForm.rules.link1' : 'règlement du concours',
            'infoForm.rules2' : ' et la ',
            'infoForm.rules.link2' : 'Politique de confidentialité de Mondelēz',
            'infoForm.rules3' : '*',
        'infoForm.optin' : "Je confirme mon consentement à ce que Mondelez Canada Inc. stocke, partage et utilise mes renseignements personnels soumis dans le but d'administrer le concours (y compris pour me notifier si je suis sélectionné en tant que gagnant admissible d'un prix) conformément au règlement du concours et à la politique de confidentialité de Mondelez.",
        'requiredFields' : '*Champs requis',
        'submit': 'SOUMETTRE',

        //winning page
        'congratulation' : 'VOUS Y ÊTES PRESQUE !',
        'winningPage.heading' : 'Vous êtes un gagnant potentiel d’une console Xbox Series S sur mesure.',
        'winningPage.body' : "Notre équipe d'assistance à la clientèle vous contactera directement dans les 24 heures pour confirmer que vous êtes un GAGNANT officiel!{br}{br}Veuillez vous assurer que l'adresse suivante est marquée comme sûre dans vos paramètres de courriel : serviceclient@rechargetescollationavecxboxconcours.ca",
        'winningPage.question' : '(6X6) – 16, puis ajouter 5 =',
        'winningPage.answer' : "Your Answer",
        'winningPage.cta' : 'SOUMETTRE',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'VOUS Y ÊTES PRESQUE !',
        'winningPage.heading2' : 'Vous êtes un gagnant potentiel d’une console Xbox Series S sur mesure.',
        'winningPage.body2' : "Notre équipe d'assistance à la clientèle vous contactera directement dans les 24 heures pour confirmer que vous êtes un GAGNANT officiel!{br}{br}Veuillez vous assurer que l'adresse suivante est marquée comme sûre dans vos paramètres de courriel : serviceclient@rechargetescollationavecxboxconcours.ca",
        'winningPage.body2a' : '.{br}{br}De plus, vous êtes inscrit au tirage au sort du grand prix!',

        'winningPage.heading3' : 'DÉSOLÉ, MAUVAISE RÉPONSE.',
        'winningPage.body3' : 'Veuillez réessayer avec un autre code UPC.',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Télécharger',

        //losing page
        'tryAgain' : 'MERCI POUR VOTRE PARTICIPATION!',
        'tryAgain.body' : "Vous avez participé au prochain tirage au sort pour avoir une chance de GAGNER*{br}{br}Vous n'êtes pas un gagnant instantané, mais il y a encore des prix de consoles Xbox Series S sur mesure à gagner.{br}{br}Entrez un autre CUP trouvé sur les ",
        'tryAgain.bodyb' : ' pour une autre chance de gagner.',
        'tryAgain.btn' : 'RÉESSAYEZ', 
        'tryAgain.heading2' : 'PARTAGEZ LE PLAISIR',
        'tryAgain.body2' : 'Faites participer vos amis à l’action ! Partagez le concours avec vos amis et faites-leur savoir qu’il y a encore des prix XBOX à gagner ! ',
        'tryAgain.body3' : 'Vous voulez partager sur Instagram? Cliquez sur le bouton de téléchargement ci-dessous et recevez une image que vous pouvez télécharger sur votre compte Instagram.',
        'popupBodylinkAlt' : 'emballages participants',

        //Prize Details
        'prizeDetails' : 'DESCRIPTION DES PRIX',

        'prizeDetails-heading1' : 'LE GRAND PRIX',
        'prizeDetails-headingsub1' : 'Expériences de « gaming » Xbox en suite',
        'prizeDetails-subheading1' : "Soyez traité comme le VIP du « gaming » que vous êtes ! Ce grand prix comprend les vols aller-retour vers Toronto et de l'argent de poche pour rendre votre voyage encore plus spécial.",
        'prizeDetails-body1' : "Enregistrez-vous et passez au niveau supérieur ! Le moment clé de votre séjour sera l'accès, pour vous et un invité, à notre suite de « gaming » exclusive de l'hôtel.{br}{br}Essayez les dernières technologies, notamment les manettes sans fil, les casques et les écrans géants multiples, tout en jouant à vos jeux préférés.{br}{br}De plus, ne partez pas les mains vides ! N'oubliez pas d'emporter votre console Xbox Series S sur mesure. pour le vol de retour !{br}{br}",
        

        'prizeDetails-heading2' : 'PRIX INSTANTANÉ',
        'prizeDetails-subheading2' : 'Consoles Xbox Series S sur mesure',
        'prizeDetails-body2' : "Chaque console Xbox Series S sur mesure est revêtue d'un design Sour Patch Kids personnalisé pour rendre ces prix amusants et super exclusifs!",
        'prizeDetails-body2a': '*Veuillez vous référer au ',
        'prizeDetails-body2b': ' pour plus de détails**',
        'rulesLink': 'règlement du concours',

        

        // Participating Products
        'products' : 'PRODUITS PARTICIPANTS',
        
        'products.group2.title' : 'Crispers',
        'products.group2' : `CHR CRISPERS CHEDDAR 145G 12{br}
        CHRISTIE CRISPERS CHEDDAR 145 G 12{br}
        CRISPERS ALL DRESSED BULK 50G 84{br}
        CRISPERS ASSAISONNÉS VRAC 50 G 84{br}
        CRISPERS DILL PICKLE 145G 12{br}
        CRISPERS CORNICHONS À L'ANETH 145 G{br}
        CRISPERS BBQ 145G 12{br}
        CRISPERS BARBECUE 145 G 12{br}
        CRISPERS RANCH 145G 12{br}
        CRISPERS RANCH 145 G 12{br}
        CRISPERS ALL DRESSED RRP 50G 3X14{br}
        CRISPERS ASSAISONNÉS 50 G 3X14{br}
        CRISPERS BITS & BITES ORIG 145G 12{br}
        CRISPERS MÉLI-MÉLO ORIGINAL 145G 12{br}
        CRISPERS BITS & BITES CHEESE 145G 12{br}
        CRISPERS MÉLI-MÉLO FROMAGE 145G 12{br}
        CRISPERS BITS & BITES BBQ 145G 12{br}
        CRISPERS MÉLI-MÉLO BBQ 145G 12{br}
        CRISPERS JALAPENO 145G 12{br}
        CRISPERS JALAPENO 145G 12{br}
        CRISPERS SMOKEY BACON 145G 12{br}
        CRISPERS BACON FUMÉ 145 G 12`,

        'products.group3.title' : 'Cadbury',
        'products.group3' : `CARAMILK 50G 6X48{br}
        CARAMILK 50G 6X48{br}
        CARAMILK 50G 6X48 GOLD{br}
        CARAMILK 50G 6X48 DOREE{br}
        CARAMILK 50G 6X48 GP{br}
        CARAMILK 50G 6X48 GP{br}
        PAL FL CARAMILK SGL 50GR 8640CT{br}
        PAL PLEIN CARAMILK REG 50GR 8640UN{br}
        CARAMILK 50G 8X24{br}
        CARAMILK 50G 8X24{br}
        CARAMILK 50G 8X24 GOLD{br}
        CARAMILK 50G 8X24 DOREE{br}
        CARAMILK 50G 8X24 GP{br}
        CARAMILK 50G 8X24 GP{br}
        CARAMILK 50G 6X48{br}
        CARAMILK 50G 6X48{br}
        WUNDERBAR 58GR 8X36{br}
        WUNDERBAR 58GR 8X36{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        PPD CARAMILK SGL FLR 192CT{br}
        PREEMB PLANC CARAMILK REG 192UN{br}
        PPD CARAMILK SGL PDQ 270CT{br}
        PREEMB PDR CARAMILK REG 270UN{br}
        DRP HALF CARAMILK SGL 1728CT{br}
        DRP DEMI CARAMILK REG 1728UN{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        CRUNCHIE KING SIZE 66G 6X24{br}
        CRUNCHIE FORM SUPER 66G 6X24 32P{br}
        MR BIG ORIGINAL 4PK 240G 10{br}
        MR BIG ORIGINALE PQT DE 4 240 G 10{br}
        CARAMILK SLTD CARAMEL 50G 8X24{br}
        CARAMILK CARAMEL SALÉ 50G 8X24{br}
        CARAMILK SLTD CARAMEL 50G 8X24 GP{br}
        CARAMILK CARAMEL SALÉ 50G 8X24 GP{br}
        CRISPY CRUNCH 48G 12X24{br}
        CRISPY CRUNCH 48G 12X24{br}
        WUNDERBAR 58G 12X24{br}
        WUNDERBAR 58G 12X24{br}
        WUNDERBAR 58G 12X24 XB{br}
        WUNDERBAR 58G 12X24 XB{br}
        WUNDERBAR 58G 12X24 XB{br}
        WUNDERBAR 58G 12X24 XB{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINALE 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        PAL FL MR BIG 60G 4032CT{br}
        PAL PLEIN MR BIG EC 60GR 4032UN{br}
        WUNDERBAR CP 58GR 12X24{br}
        WUNDERBAR EC 58GR 12X24{br}
        CRUNCHIE CP 44GR 12X24{br}
        CRUNCHIE EC 44GR 12X24{br}
        CRUNCHIE 44G 12X24{br}
        CRUNCHIE 44G 12X24{br}
        CRUNCHIE 44G 12X24{br}
        MR BIG KING SIZE 90G 6X24{br}
        MR BIG FORMAT SUPER 90G 6X24{br}
        WUNDERBAR KING SIZE 90G 6X24{br}
        WUNDERBAR FORMAT SUPER 90G 6X24{br}
        CARAMILK KING SIZE 78G 6X24{br}
        CARAMILK FORMAT SUPER 78G 6X24{br}
        CARAMILK KING SIZE 78G 6X24 GOLD{br}
        CARAMILK FORMAT GÉANT 78 G 6X24{br}
        PPD MR BIG ORIGINAL CTR 96CT{br}
        PREEMB COMPT MR BIG ORIGINALE 96UN{br}
        PPD MR BIG ORIGINAL PDQ 132CT{br}
        PREEMB PDR MR BIG ORIGINALE 132 UN{br}
        PPD MR BIG DEAL CTR 96CT{br}
        PPD COMPT MR BIG DEAL 96UN{br}
        PPD MR BIG DEAL PDQ 132CT{br}
        PPD PDR MR BIG DEAL 132UN`,

        'products.group1.title' : 'Maynards/Sour Patch Kids',
        'products.group1' : `SOUR PATCH KIDS BIG HEADZ 154G 12{br}
        SOUR PATCH KIDS BIG HEADZ 154G 12{br}
        SOUR PATCH KIDS BERRIES 150G 12{br}
        SOUR PATCH KIDS BAIES 150 G 12{br}
        SPK WATERMELON 154G 12{br}
        SPK MELON D'EAU 154G 12{br}
        SOUR PATCH KIDS 150G 12{br}
        SOUR PATCH KIDS 150G 12{br}
        SOUR PATCH KIDS STRAWBERRY 154G 12{br}
        SOUR PATCH KIDS FRAISE 154 G 12{br}
        SOUR PATCH KIDS FUN MIX 315G 12{br}
        SPK RÉGALS-ÉCLAIR 315G 12{br}
        SPK STRAWBERRY 154G 2X9{br}
        SPK FRAISE 154G 2X9{br}
        SPK SR CHRY BLASTERS 154G 8{br}
        SPK SR CHRY BLASTERS 154G 8{br}
        SOUR PATCH KIDS BIG HEADZ 154G 8{br}
        SOUR PATCH KIDS BIG HEADZ 154G 8{br}
        SOUR PATCH KIDS BERRIES 150G 8{br}
        SOUR PATCH KIDS BAIES 150 G 8{br}
        SPK WATERMELON 154G 8{br}
        SPK MELON D'EAU 154G 8{br}
        SOUR PATCH KIDS 150G 8{br}
        SOUR PATCH KIDS 150G 8{br}
        SOUR PATCH KIDS STRAWBERRY 154G 8{br}
        SOUR PATCH KIDS FRAISE 154 G 8{br}
        MAYNARDS FUZZY PEACH 154G 8{br}
        MAYNARDS FUZZY PEACH 154G 8{br}
        MAYNARDS WINE GUMS 154G 8{br}
        MAYNARDS WINE GUMS 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        MAYNARDS ORIGINAL GUMMIES 150G 8{br}
        MAYNARDS ORIGINAL GUMMIES 150G 8{br}
        SPK SR CHRY BLASTERS 154G 12{br}
        SPK SR CHRY BLASTERS 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS WINE GUMS 154G 12{br}
        MAYNARDS WINE GUMS 154G 12{br}
        MAYNARDS FUZZY PEACH 154G 12{br}
        MAYNARDS FUZZY PEACH 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        MAYNARDS ORIGINAL GUMMIES 150G 12{br}
        MAYNARDS ORIGINAL GUMMIES 150G 12{br}
        SOUR PATCH KIDS 315G 12{br}
        SOUR PATCH KIDS 315G 12{br}
        SPK WATERMELON 315G 12{br}
        SPK MELON D'EAU 315G 12{br}
        SPK SRCHRY BLASTERS 308G 12{br}
        SPK SRCHRY BLASTERS 308G 12{br}
        MAYNARDS SWEDBRY TROPICAL 315G 12{br}
        MAYNARDS SWEDBRY TROPICAL 315 G 12{br}
        MAYNARDS SWEDISH BERRIES 315G 12{br}
        MAYNARDS SWEDISH BERRIES 315G 12{br}
        MAYNARDS FUZZY PEACH 308G 12{br}
        MAYNARDS FUZZY PEACH 308G 12{br}
        MAYNARDS SWEDISH BERRIES 154G 12{br}
        MAYNARDS SWEDISH BERRIES 154G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 12{br}
        SOUR PATCH KIDS LIMONADE 150 G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 8{br}
        SOUR PATCH KIDS LIMONADE 150G 8{br}
        SOUR PATCH KIDS BLUE 150G 12{br}
        SOUR PATCH KIDS BLEU 150 G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12 XB{br}
        MAYNARDS SOUR PATCH KIDS 185 G 12 XB{br}
        MAYNARDS WINE GUM ROLLS 44G 12X18{br}
        MAYNARDS WINE GUM ROLLS 44G 12X18{br}
        MAYNARDS WINE GUMS 315G 12{br}
        MAYNARDS WINE GUMS 315G 12{br}
        MAYNRD SPK SRCHRY BLASTER 64G 12X18{br}
        MAYNRD SPK SRCHRYBLASTER 64G 12X18{br}
        MAYNARDS FUZZY PEACH 64G 12X18{br}
        MAYNARDS FUZZY PEACH 64G 12X18{br}
        MAYNARDS SWEDISH BERRIES 64G 12X18{br}
        MAYNARDS SWEDISH BERRIES 64G 12X18{br}
        MAYNARDS ORIG GUMMIES 60G 12X18{br}
        MAYNARDS ORIG GUMMIES 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9 XB{br}
        MAYNARDS SOUR PATCH KIDS 185 G 2X9 XB{br}
        MAYNARDS FUZZY PEACH BOX 100G 12{br}
        MAYNARDS FUZZY PEACH BOITE 100G 12{br}
        MAYNRD SOUR WATERMELON BOX 100G 12{br}
        MAYNRD SOUR WATERMELON BTE 100G 12{br}
        MAYNRD SWEDISH BERRIES BOX 100G 12{br}
        MAYNRD SWEDISH BERRIES BTE 100G 12{br}
        MAYNARDS SPK EXTREME BOX 100G 12{br}
        MAYNARDS SPK EXTREME BOITE 100G 12{br}
        MAYNARDS WINE GUMS DRC 315G 10{br}
        MAYNRD WINE GUMS BTE PRES 315G 10{br}
        SOUR PATCH KIDS EXTREME 150G 12`,

        //faq page
        'faq-heading-text1': 'Bien que ',
            'faq-heading-link': 'le règlement',
        'faq-heading-text2': ' détaille toutes les modalités de cette promotion, il se peut que les réponses à vos questions se trouvent déjà ci-dessous.',

        'q1': 'Q1. Quelles sont les dates de cette promotion?',
        'a1': 'A. Le concours commence le 11 septembre et se termine le 8 octobre 2023.',
        
        'q2': 'Q2. Quels sont les prix de cette promotion?',
        'a2': "A.  Prix disponibles au début du concours :{br}{br}Il y aura trois (3) grands prix à gagner. Chacun d'entre eux consiste en un weekend d’expérience de « gaming » Xbox en suite à Toronto (VDA 7500 $ CA chacun). Le premier voyage doit être effectué du 17-19 novembre 2023, le deuxième voyage doit être effectué du 24-26 novembre et le troisième voyage doit être effectué du 1-3 décembre 2023.{br}{br}Il y aura aussi dix (10) prix instantanés de consoles Xbox Series S sur mesure disponibles à gagner pendant le concours (VDA 750 $ CA chacun).",
        
        'q2-b' : 'Q3. Comment puis-je participer sans acheter de produit?        ',
        'a2-b' : "A. Pour participer sans faire d'achat, vous pouvez demander un (1) code CUP en envoyant un texte unique et original de cinquante (50) mots sur votre jeu vidéo Xbox préféré et pourquoi, ainsi que votre nom complet, votre âge et votre adresse électronique sur une feuille de papier de 8 po x 11 po (la “ demande écrite ”) et en l'envoyant dans une enveloppe dûment affranchie au Canada à l’“Recharge tes collations avec Xbox 2023”, 6-6150 Highway 7, Suite #154, Woodbridge, ON, L4H 0R6.",
        
        'q3': 'Q4. Combien de fois puis-je participer?        ',
        'a3': "A. Le nombre de participations est limité à une (1) par personne et par jour.        ",
        
        'q4': 'Q5. Combien de prix puis-je gagner?        ',
        'a4': "A. Il y a une limite d'un (1) grand prix par personne. De plus, il y a une limite d'un PRIX INSTANTANÉ par personne pendant la durée de la promotion.",

        'q5': "Q6. J'ai un CUP valide, mais je reçois un message d'erreur.        ",
        'a5': "A. Veuillez saisir le(s) code(s) sans tirets ni espaces et consultez notre liste de ",
        'a5a': 'produits participants',
        'a5b': ' pour vous assurer que votre achat est admissible. Si le code ne fonctionne toujours pas, nous sommes là pour vous aider!{br}{br}Veuillez nous contacter au ',

        'q6': "Q7. J'ai reçu un message m'indiquant que j’avais déjà participé aujourd'hui, mais ce n'est pas le cas. Que dois-je faire?",
        'a6': 'A. Assurez-vous de visiter ',
            'a6-link': 'https://rechargetescollationsavecxbox.ca/',
            'a6-bottom': " directement plutôt qu'une page mise en signet dans votre navigateur. Essayez également d'effacer les cookies et le cache de votre navigateur Internet.",

        'q7': "Q8. J'ai des problèmes techniques. Que dois-je faire? ",
        'a7': "A. Parfois, il s'agit d'une chose simple comme la mise à jour de votre navigateur. Vérifiez que vous disposez d'une version moderne de Chrome, Internet Explorer, Firefox ou Safari. Vous pouvez également consulter les préférences de votre navigateur pour vous assurer que JavaScript est activé. La suppression du cache et des cookies peut également s'avérer utile dans ce cas.",

        'q8': "Q9. Comment saurai-je si je suis un gagnant potentiel d'un prix INSTANTANÉ?        ",
        'a8': "Vous serez informé à l'écran si vous avez été déclaré gagnant potentiel d'un prix instantané. Vous serez alors contacté directement par courriel par notre équipe d'assistance à la clientèle à l'adresse serviceclient@rechargetescollationavecxboxconcours.ca pour remplir le formulaire de déclaration et d'exonération, y compris répondre correctement à une question d'habileté mathématique avant d'être déclaré officiellement gagnant.",

        // 'q9': "Q10. J'ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations de participation à cette promotion?        ",
        // 'a9': "A. Nous nous excusons pour ce désagrément, mais en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations relatives à la participation. Si vous participez au tirage au sort du grand prix pendant la période promotionnelle et que vos coordonnées changent, contactez serviceclient@rechargetescollationavecxboxconcours.ca et fournissez vos coordonnées mises à jour. Remarque : si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous pourrons répondre à votre demande. Vous avez encore une question ? N'hésitez pas à nous contacter à  serviceclient@rechargetescollationavecxboxconcours.ca. Veuillez noter qu'une réponse peut prendre jusqu'à trois (3) jours ouvrables. ",

        // 'q10': 'Q. J’ai des difficultés à faire fonctionner mon PC Game Pass ? Que devrais-je faire ?',
        // 'a10': 'A. Pour plus de détails sur la façon d’échanger un code, visitez ',
        // 'a10-link': 'https://support.xbox.com/fr-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        'q11': "Q10. J'ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations de participation à cette promotion?",
        'a11': 'A. Nous nous excusons pour ce désagrément, mais en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations relatives à la participation.{br}{br} Si vous participez au tirage au sort du grand prix pendant la période promotionnelle et que vos coordonnées changent, contactez ',
        'a11-2' : ' et fournissez vos coordonnées mises à jour.{br}{br}Remarque : si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous pourrons répondre à votre demande.{br}{br}Vous avez encore une question ? N’hésitez pas à nous contacter à ',
        'a11-3' : ". Veuillez noter qu'une réponse peut prendre jusqu'à trois (3) jours ouvrables.",

        //Rules page
        'rules-page-heading': 'RÈGLEMENT',
    }
}