import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
// import ReactGA, { OutboundLink } from 'react-ga';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';

//language file
import { translate } from '../../i18n/translate';

class InfoForm extends Component{

    state = {
        buttonHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //overlay css
    overlayCSS = {
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.01)',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99',
        display: 'none',
        transition: 'all .3s ease'
    }

    //overlay active css
    overlayActiveCSS = {
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.6)',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99',
        display: 'block',
        transition: 'all .3s ease'
    }

    componentDidMount(){

        window.scrollTo(0, 0);

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        const form = this.form;

        gsap.from(form, .6, {
            opacity: 0,
            y: 100,
            ease: Power3.easeOut
        })

        //animating error message
        const {error} = this;
        if (error) {
            gsap.from(error, .6, {
                opacity: 0,
                y: 100,
                ease: Power3.easeOut
            })
        }
    }

    render(){

        const { handleChange, handleCheckbox, values, errorMessages, handleReCaptcha } = this.props;
        const { invalidEntry, tooManyReqIp, tooManyReqEmail, internalError, dbError, badReq, captchaError, closeWarningfromInfoForm } = this.props;
        const { loading, lang } = this.props;

        return(
            <Fragment>
                <div className="main-wrapper alt-bg">
                    <div id="container" className="container info-form__container">
                        <p className="steps">{translate('step2')}</p>
                        <h1 className="form__heading pin-form__heading">{translate('enterInfo')}</h1>
                        <p className="form__description pin-form__description">{translate('enterInfoDescription')}</p>
                        
                        <div className="mui-container info-form" ref={el => { this.form = el}}>
                            <form onSubmit={handleReCaptcha}>
                                <div className="row">
                                    <div className="col-12">
                                        <TextField className={errorMessages.fNameError ? 'error' : ''} variant="filled"  label={translate('firstName')} defaultValue={values.fName} onChange={handleChange('fName')} type="text" required helperText={errorMessages.fNameError} autoComplete='given-name' name='firstName' />
                                        <TextField className={errorMessages.lNameError ? 'error' : ''} variant="filled"  label={translate('lastName')} defaultValue={values.lName} onChange={handleChange('lName')} type="text" required helperText={errorMessages.lNameError} autoComplete='family-name' name='lastName' />
                                        <TextField className={errorMessages.emailError ? 'error' : ''} variant="filled"  label={translate('emailAddress')} defaultValue={values.email} onChange={handleChange('email')} type="email" required helperText={errorMessages.emailError} autoComplete='email' name='email' />
                                        <TextField className={errorMessages.phoneError ? 'error' : ''} variant="filled"  label={translate('phone')} defaultValue={values.phone} onChange={handleChange('phone')} type="phone" required helperText={errorMessages.phoneError} autoComplete='tel' name='phone' />
                                 
                                    </div>
                                    <div className="col-12">
                                        <div className="checkbox-input">
                                            <FormControlLabel value={values.age} control={ <Checkbox required checked={values.age} disableRipple name="age" onClick={handleCheckbox} label="Age" style={{color: '#FFFFFF'}} /> } label="" labelPlacement="end" />
                                            <p className="checkbox-input__label">{translate('infoForm.age')}</p>
                                        </div>
                                        <p className="infoForm__errorMessage">{errorMessages.ageError}</p>

                                        <div className="checkbox-input">
                                            <FormControlLabel value={values.rules} control={ <Checkbox required checked={values.rules} disableRipple name="rules" onClick={handleCheckbox} label="Rules" style={{color: '#FFFFFF'}} /> } label="" labelPlacement="end" />
                                            <p className="checkbox-input__label">
                                                {translate('infoForm.rules')}
                                                <NavLink exact to='/rules' target="_blank">{translate('infoForm.rules.link1')}</NavLink>
                                                {translate('infoForm.rules2')}
                                                <a href={lang === "fr-CA" ? "https://www.mondelezinternational.com/canada-french/privacy-policy/" : "https://www.mondelezinternational.com/canada/privacy-policy/"}
                                                    target="_blank" rel="noopener noreferrer">
                                                        {translate('infoForm.rules.link2')}
                                                </a>
                                                {translate('infoForm.rules3')}
                                            </p>
                                        </div>
                                        <p className="infoForm__errorMessage">{errorMessages.rulesError}</p>

                                        <div className="checkbox-input">
                                            <FormControlLabel value={values.optin} control={ <Checkbox required checked={values.optin} disableRipple name="optin" onClick={handleCheckbox} label="Newsletter" style={{color: '#FFFFFF'}} /> } label="" labelPlacement="end" />
                                            <p className="checkbox-input__label">{translate('infoForm.optin')}
                                            </p>
                                           
                                        </div>
                                        <p className="infoForm__errorMessage">{errorMessages.optinError}</p>
                                        <p className='required'>{translate('required')}</p>
                                        <div className="checkbox-input">
                                            <FormControlLabel control={ <Checkbox required hidden disableRipple name="placehoder" onClick={handleCheckbox} label="Newsletter" style={{color: '#FFFFFF'}} tabIndex="0" /> } label="" labelPlacement="end" />
                                        </div>
                                        
                                    </div>
                                </div>
                                <button className="info-form__submit-btn btn-secondary" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onClick={handleReCaptcha} disabled={values.pin !== "" ? false : true} >
                                    <p>{translate('submit')}</p>
                                </button>
                            </form>
                        </div>

                    </div>

                    {invalidEntry && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Un ou plusieurs champs erroné(s).' : 'One or more entries are invalid'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {tooManyReqIp && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Trop de demandes de participation à partir de la même adresse IP.' : 'Too many requests from the same IP'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {tooManyReqEmail && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Vous avez atteint le nombre maximal de participations par jour. Veuillez réessayer demain.' : 'You\'ve reached the maximum number of entries per day. Please try again tomorrow.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {internalError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 'Sorry, there was an error on our side. Please try again.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {dbError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Le serveur est actuellement en maintenance. Essayez à nouveau.' : 'The server is currently undergoing maintenance. Please try again later.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {badReq && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 'Sorry, there was an error on our side. Please try again.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}
                    {captchaError && <div className="pin-not-found" ref={el => this.error = el}><p>{lang === 'fr-CA' ? 'Veuillez vérifier que vous n’êtes pas un robot.' : 'Please verify that you are not a robot.'}</p><button onClick={closeWarningfromInfoForm}>{translate('close')}</button></div>}

                </div>
                <div className="api-calling-overlay" style={loading ? this.overlayActiveCSS : this.overlayCSS }></div>
            </Fragment>
        )
    }
}

export default InfoForm;