import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';

import forza from '../../images/forza-white.png';
import xbox from '../../images/xbox.png';

class ContestClosed extends Component {

    state = {
        isHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }


    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        gsap.from([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .1
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home comingsoon">
                        <div className="row">
                            <div className="col home-text-container text-center"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <div>
                                        <h1 ref={el => {this.headingOne = el}}>
                                            <span>{translate('comingsoon.head1')}</span>
                                            <span>{translate('comingsoon.head2')}</span>
                                            {translate('comingsoon.head3')}
                                        </h1>
                                    </div>
                                    <div className="logos">
                                        <img src={forza} alt="Forza Motorsport" />
                                    </div>
                                </div>
                                <div className='home-body-text-wrap'>
                                    <p className="home-body-text">{translate('comingsoon', {sup:e=><sup>{e}</sup>})}</p>
                                </div>
                                <img src={xbox} alt="Custom Xbox" className="hero-box" />
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row justify-content-center">
                            <div className="col-md-10 disclaimer">
                                {translate('legal',{link:<NavLink exact to="/">{translate('legal.link')}</NavLink>})}
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;